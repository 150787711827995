<!--重出转寄--搜索库存-->
<template>
    <div style="margin-top: -35px;">
        <div style="padding: 15px;">
            <div style="font-weight:bold;font-size:16px;">{{msg('待搜索信息')}}：</div>
            <div style="display:flex; flex-wrap: wrap;" v-if="type == 2">
                <span ><span style="color: #000; font-size:14px;">{{msg('待搜索的单号')}}：</span>{{resend.resendCode}}</span>
            </div>
            <div style="display:flex; flex-direction: column;" v-else>
                <span><span style="color: #000; font-size:14px;">{{msg('标题')}}：</span><span style="font-weight:bold;font-size:14px;">{{resend.resendTitle}}</span></span>
                <span><span style="color: #000;font-size:14px;">{{msg('规格')}}：</span><span style="font-weight:bold;font-size:14px;">{{resend.resendSpec}}</span></span>
                <span><span style="color: #000;font-size:14px;">{{msg('件数')}}：</span><span style="font-weight:bold;font-size:14px;">{{resend.resendQty}}</span></span>
                <span><span style="color: #000;font-size:14px;">{{msg('SKU')}}：</span><span style="font-weight:bold;font-size:14px;">{{sku}}</span></span>
                <span><span style="color: #000;font-size:14px;">{{msg('货号')}}：</span><span style="font-weight:bold;font-size:14px;">{{huohao}}</span></span>
            </div>
        </div>
        <el-row style="margin-bottom:10px; margin-top:10px;">
            <el-col :span="12">
                <div style="display:flex; align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('仓库')}}：</div>
                    <el-select v-model="query.storeIds" multiple :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="store in stores" :key="store.id" :label="store.name" :value="store.id"></el-option>
                    </el-select>
                </div>
            </el-col>
            <el-col :span="12">
                <div style="display:flex; align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('仓储类型')}}：</div>
                    <el-select v-model="query.storeTypes" multiple :placeholder="msg('请选择')" size="mini">
                        <el-option v-for="(type, idx) in dicts.stockStoreType1" :key="idx" 
                            :label="type.label" :value="type.value"></el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
               <div style="display:flex;  align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('库存单号')}}：</div>
                    <el-input v-model="query.code" size="mini"></el-input>
                </div>
            </el-col>
            <el-col :span="12" v-if="type != 2">
                <div style="display:flex; align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('关键字')}}：</div>
                    <el-input v-model="query.keyword" size="mini"></el-input>
                </div>
            </el-col>
        </el-row>
        <el-row style="margin-bottom:10px; margin-top:10px;">
            <el-col :span="12">
               <div style="display:flex;  align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('货号')}}：</div>
                    <el-input v-model="query.variationSku" size="mini"></el-input>
                </div>
            </el-col>
            <el-col :span="12">
               <div style="display:flex;  align-items:center;">
                    <div style="min-width:80px; text-align:right;">{{msg('SKU')}}：</div>
                    <el-input v-model="query.sku" size="mini"></el-input>
                </div>
            </el-col>
        </el-row>
        <div style="margin: 15px 40px 15px 0px; text-align:right;">
            <el-button type="primary" size="mini" icon="el-icon-search" @click="searchStock">{{msg('搜索')}}</el-button>
            <el-button type="success" style="margin-right:20px;" @click="enterCheck" size="mini" icon="el-icon-check">{{msg('确定选择')}}</el-button>
        </div>
        <div>
            <vxe-table border resizable 
                    ref="dataTable"
                    size="mini" show-header-overflow
                    highlight-hover-row max-height="500"
                    :sort-config="{trigger: 'cell'}"
                    :data="datas">
                <vxe-table-column type="checkbox" field="id"  min-width="50"></vxe-table-column>
                <vxe-table-column :title="msg('库存单号')" show-overflow field="code" min-width="120"></vxe-table-column>
                <vxe-table-column :title="msg('入仓时间')" show-overflow field="inStoreTime" min-width="120"></vxe-table-column>
                <vxe-table-column :title="msg('总件数')" field="productQty" width="70"></vxe-table-column>
                <vxe-table-column :title="msg('可用数量')" field="balanceQty" width="70"></vxe-table-column>
                <vxe-table-column :title="msg('标题')" show-overflow field="productTitle" min-width="200">
                    <template v-slot="{ row }">
                        <div>{{row.productTitle}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('规格')" show-overflow field="productSpec" min-width="135">
                    <template v-slot="{ row }">
                        <div>{{row.productSpec}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('货号')" show-overflow field="variationSku" width="100"></vxe-table-column>
                <vxe-table-column :title="msg('SKU')" show-overflow field="titleSku" width="100"></vxe-table-column>
                <vxe-table-column :title="msg('架位号')" show-overflow field="shelfCode" width="100"></vxe-table-column>
                <vxe-table-column :title="msg('归属人')" show-overflow field="ownerName" width="100"></vxe-table-column>
                <vxe-table-column :title="msg('仓库')" show-overflow field="storeName" width="100">
                    <template v-slot="{ row }">
                        <div>{{row.storeName}}</div>
                    </template>
                </vxe-table-column>
                <vxe-table-column :title="msg('仓储类型')" field="storeType" width="80">
                    <template v-slot="{ row }">
                        <div>{{row.storeTypeLabel}}</div>
                    </template>
                </vxe-table-column>
            </vxe-table>
        </div>
    </div>

</template>

<script>
import XEUtils from 'xe-utils';
export default {
    name: "StockMatchSearch",
    created(){
    },
    data(){
        return{
            query:{
                code: null,
                keyword: null,
                storeIds: [],
                storeTypes:[],
                variationSku:null,
                sku:null,
            },
            datas: [],
            sku:null,
            huohao:null,
        }
    },
    props:{
        stores: Array, //可选仓库
        useds: Array,  //已经使用过的库存
        resend: Object, //选中的，待搜索的物流
        type: Number, //2--选中整条库存
        skuId: Number,//选中的详情ID
    },
    methods:{
        init(){
            this.query.code = null;
            this.query.keyword = null;
            this.query.storeIds = [];
            this.query.storeTypes = [];
            this.query.variationSku = null;
            this.query.sku = null;
            this.datas = [];
            this.sku = null;
            this.getSku();
        },

        // mergeRowMethod({ row, _rowIndex, column, visibleData }){
        //     const fields = ['id', 'code'];
        //     if(!column.property || !visibleData || this.type != 2){
        //         return { rowspan: 1, colspan: 1 }
        //     }
        //     const cellValue = XEUtils.get(row, column.property)
        //     if (cellValue && fields.includes(column.property)) {
        //         const prevRow = visibleData[_rowIndex - 1]
        //         let nextRow = visibleData[_rowIndex + 1]
        //         if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
        //             return { rowspan: 1, colspan: 1 }
        //         } else {
        //             let countRowspan = 1
        //             while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
        //                 nextRow = visibleData[++countRowspan + _rowIndex]
        //             }
        //             if (countRowspan > 1) {
        //                 return { rowspan: countRowspan, colspan: 1 }
        //             }
        //         }
        //     }
        // },
        getSku(){
            this.$axios.post(this.$kit.api.resend.searchSku+'?detailId='+this.skuId)
                .then( (result) => {
                    if(result.status){
                        this.sku = result.data.sku;
                        this.huohao = result.data.huohao;
                    }
                });
        },
        mergeRowMethod ({ row, _rowIndex, column, visibleData }) {
              const fields = ['code']
              const cellValue = XEUtils.get(row, column.property)
              if (cellValue && fields.includes(column.property)) {
                const prevRow = visibleData[_rowIndex - 1]
                let nextRow = visibleData[_rowIndex + 1]
                if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
                  return { rowspan: 0, colspan: 0 }
                } else {
                  let countRowspan = 1
                  while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
                    nextRow = visibleData[++countRowspan + _rowIndex]
                  }
                  if (countRowspan > 1) {
                    return { rowspan: countRowspan, colspan: 1 }
                  }
                }
              }
        },
        //搜索库存
        searchStock(){

            let param = {
                code: this.query.code,
                keyword: this.query.keyword,
                storeIds: this.query.storeIds.length>0?this.query.storeIds.join(","):null,
                storeTypes: this.query.storeTypes.length>0?this.query.storeTypes.join(","):null,
                type: this.type,
                variationSku: this.query.variationSku,
                sku:this.query.sku,
            }

            this.$axios.post(this.$kit.api.resend.search, param)
                .then( (result) => {
                    if(result.status){
                        // console.log(this.useds);
                        let data = result.data.list;

                        let filtered = [];
                        data.forEach(d => {

                            let storeTypeLabel = this.dicts.stockStoreType1.find(e => e.value == d.storeType);
                            if(storeTypeLabel){
                                d.storeTypeLabel = storeTypeLabel.label;
                            }else{
                                d.storeTypeLabel =  d.storeType;
                            }
                            d.storeName = d.storeName || "默认仓库";

                            let used = this.useds.find(u => u.stockDetailId == d.detailId);
                            if(this.type == 2){
                                if(!used){
                                    filtered.push(d);
                                }
                            }else{
                                if(used){ //将剩余的关联起来
                                    d.balanceQty = used.balanceQty;
                                    d.used = used;
                                }else{
                                    d.balanceQty = d.productQty;                                
                                }
                                if(d.balanceQty > 0){
                                    filtered.push(d);
                                }
                            }
                        });
                        this.datas = filtered;
                        console.log(this.datas);
                    }else{
                        this.$message(result.msg); 
                    }
                });
        },
        enterCheck(){

            //检查库存，如果库存是0,则不与匹配
            var selectes = this.$refs.dataTable.getCheckboxRecords();
            if(selectes.length == 0){
                this.$message(this.msg("请选择要转寄的库存"));
                return;
            }

            if(this.type == 2){
                
                if(selectes.length > 1){
                    this.$message(this.msg("只能选择一条数据进行转寄"));
                    return;
                }else{
                    let select = selectes[0];
                    selectes = this.datas.filter(d => d.id == select.id);
                }
            }

            this.$emit("close-dg", selectes,this.type);
        },

        

    }
}
</script>

<style lang="scss" scoped>
    .el-dialog__body {
    padding: 0px 20px;}
    .resend-box h2{padding-left: 15px; font-size:20px; color: rgba(100,100,100,0.9)}
    .resend-box h3{padding-left: 15px; font-size:16px; color: rgba(100,100,100,0.9)}
    .mytable-style{
        border: 1px solid rgba(0,0,0,0.7) !important;
    }
</style>