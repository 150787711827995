<!--重出转寄-->
<template>
    <div class="resend-box">
           
        <div style="text-align:center;">
            <el-select v-model="storeIds" style="margin-top:15px; margin-bottom:15px; width:calc(100% - 300px); max-width: calc(100% - 300px); min-width: 300px;" multiple :placeholder="msg('请选择')" size="mini">
                <el-option v-for="store in stores" :key="store.id" :label="store.name" :value="store.id"></el-option>
            </el-select>
            <el-button type="primary" size="mini" icon="el-icon-check" v-if="hasPermission('stock:autoResend')" @click="previewResend">{{msg('开始自动匹配')}}</el-button>
            <el-button type="success" size="mini" icon="el-icon-check" @click="getExpData">{{msg('手动匹配')}}</el-button>
        </div>

        <h3 v-show="datas.length>0">{{msg('自动匹配成功的订单')}}</h3>
        <div v-if="datas.length>0" class="table-wrap">
            <vxe-table border resizable 
                    ref="dataTable"
                    size="mini" show-header-overflow
                    highlight-hover-row :max-height="height1"
                    class="mytable-style"
                    :cell-style="mytd"
                    :header-cell-style="myth"
                    :sort-config="{trigger: 'cell'}"
                    :span-method="mergeRowMethod"
                    :scroll-y="{gt: -1}"
                    :data="datas">
                <vxe-table-colgroup class="gropu0" :title="msg('待转寄订单')" :fixed="docWidth<950?'':'left'" field="group0" header-align="center">
                    <vxe-table-column  field="resendId" :title="msg('订单编号')" width="80" sortable>
                        <template v-slot="{ row }">
                            {{row.resendCode}}
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('标题')" show-overflow  field="resendTitle" min-width="200">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('规格')" show-overflow field="resendSpec" min-width="135">
                        <template v-slot="{ row }">
                            <div>{{row.resendSpec}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('货号')" show-overflow  field="resendVariationSku" min-width="100">
                        <template v-slot="{ row }">
                            <div>{{row.resendVariationSku}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('SKU')" show-overflow  field="resendSku" min-width="100">
                        <template v-slot="{ row }">
                            <div>{{row.resendSku}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('转寄单号')" show-overflow field="resendStockCode" width="120"></vxe-table-column>
                    <vxe-table-column :title="msg('件数')" field="resendQty" width="50" align="center"></vxe-table-column>
                </vxe-table-colgroup>
                <vxe-table-colgroup class="gropu1" :title="msg('现有库存')" field="group1" header-align="center">
                    <vxe-table-column :title="msg('件数(总/余)')" field="stock.balanceQty" width="100" align="center">
                        <template v-slot="{ row }">
                            <div>{{row.stock?(row.stock.totalQty +"/"+row.stock.balanceQty):""}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('库存单号')" show-overflow field="stock.stockCode" width="120"></vxe-table-column>
                    <vxe-table-column :title="msg('入仓时间')" show-overflow field="stock.inStoreDate" width="80"></vxe-table-column>
                    <vxe-table-column :title="msg('标题')" show-overflow field="stock.stockTitle" min-width="200">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('规格')" show-overflow field="stock.stockSpec" min-width="135">
                        <template v-slot="{ row }">
                            <div>{{row.stock? row.stock.stockSpec:""}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('货号')" show-overflow  field="stock.variationSku" min-width="100">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('SKU')" show-overflow  field="stock.sku" min-width="100">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('仓库')" show-overflow field="stock.storeName" width="100">
                        <template v-slot="{ row }">
                            <div>{{row.stock?(row.stock.storeName?row.stock.storeName:"默认仓库"):""}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('仓库类型')" field="stock.storeType" width="70">
                        <template v-slot="{ row }">
                            <div>{{ getStoreType(row)}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('操作')" field="msg" width="70">
                         <template v-slot="{ row, rowIndex }">
                            <div>
                                <el-link v-if="hasPermission('stock:autoResendSearch')" :type="row.stock?'success':'danger'" :icon="row.stock?'el-icon-circle-check':'el-icon-circle-close'" style="font-size:20px;" :underline="false"></el-link>
                                <el-link v-if="hasPermission('stock:autoResendSearch')" type="primary" icon="el-icon-search" style="font-size:20px;padding-left:5px;" :underline="false" @click="searchStock(row, rowIndex, 0)"></el-link>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
                <vxe-table-colgroup :title="msg('其他')" field="group2" header-align="center">
                    <vxe-table-column field="resendId1" show-overflow :title="msg('货单备注')" width="150">
                        <template v-slot="{ row, rowIndex}">
                            <div style="text-align:center;">
                                 {{row.resendRemark}}
                                <el-link type="primary" @click="editRemark(row, rowIndex, 0)" icon="el-icon-edit" style="font-size:18px;" :underline="false"></el-link>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
                <vxe-table-colgroup :title="msg('操作')" field="group3" header-align="center" fixed='right' :show-overflow="false">
                    <vxe-table-column field="resendId3"  min-width="70" :title="msg('移除')" >
                        <template v-slot="{ row, rowIndex }">
                            <div>
                                <el-link type="warning" icon="el-icon-bottom" style="font-size:20px;" :underline="false" @click="moveToUnmatch(row, rowIndex, 0)" ></el-link>
                                <el-link type="danger" icon="el-icon-delete" style="font-size:20px;padding-left:5px;" :underline="false" @click="removeRow(row, rowIndex, 0)"></el-link>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
            </vxe-table>
        </div>

        <div v-if="datas1.length>0" style="display:flex; justify-content: space-around; align-items:center;">
            <h3 v-show="datas1.length>0">{{msg('需要手动匹配的订单')}}</h3>
            <div>
                <el-button type="success" size="mini" icon="el-icon-serach" @click="searchMainStock">{{msg('搜索库存')}}</el-button>
                <el-button type="success" size="mini" icon="el-icon-remove" @click="tableRemove">{{msg('批量移除')}}</el-button>
            </div>
        </div>
        <div v-if="datas1.length>0" class="table-wrap">
            <vxe-table border resizable 
                    ref="dataTable1"
                    size="mini" show-header-overflow
                    highlight-hover-row :max-height="height2"
                    class="mytable-style"
                    :cell-style="mytd"
                    :header-cell-style="myth"
                    :sort-config="{trigger: 'cell'}"
                    :scroll-y="{gt: -1}"
                    :span-method="mergeRowMethod"
                    @checkbox-change="changeData1Select"
                    :data="datas1">
                <vxe-table-colgroup class="gropu0" :title="msg('待转寄订单')" :fixed="docWidth<950?'':'left'" field="group0" header-align="center">
                    <vxe-table-column  type="checkbox" field="resendId" width="40">
                    </vxe-table-column>
                    <vxe-table-column  field="resendCode" :title="msg('订单编号')" width="80" sortable>
                        <template v-slot="{ row }">
                            {{row.resendCode}}
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('标题')" show-overflow  field="resendTitle" min-width="160">
                        <template v-slot="{ row }">
                            <div>{{row.resendTitle}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('规格')" show-overflow field="resendSpec" min-width="135">
                        <template v-slot="{ row }">
                            <div>{{row.resendSpec}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('货号')" show-overflow  field="resendVariationSku" min-width="100">
                        <template v-slot="{ row }">
                            <div>{{row.resendVariationSku}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('SKU')" show-overflow  field="resendSku" min-width="100">
                        <template v-slot="{ row }">
                            <div>{{row.resendSku}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('转寄单号')" show-overflow field="resendStockCode" width="120"></vxe-table-column>
                    <vxe-table-column :title="msg('件数')" field="resendQty" width="50" align="center"></vxe-table-column>
                </vxe-table-colgroup>
                <vxe-table-colgroup class="gropu1" :title="msg('现有库存')" field="group1" header-align="center">
                    <vxe-table-column :title="msg('件数(总/余)')" field="stock.balanceQty" width="100" align="center">
                        <template v-slot="{ row }">
                            <div>{{row.stock? (row.stock.totalQty +"/"+row.stock.balanceQty):""}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('库存单号')" show-overflow field="stock.stockCode" width="120"></vxe-table-column>
                     <vxe-table-column :title="msg('入仓时间')" show-overflow field="stock.inStoreDate" width="80"></vxe-table-column>
                    <vxe-table-column :title="msg('标题')" show-overflow field="stock.stockTitle" min-width="200">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('规格')" show-overflow field="stock.stockSpec" min-width="135">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('货号')" show-overflow  field="stock.variationSku" min-width="100">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('SKU')" show-overflow  field="stock.sku" min-width="100">
                    </vxe-table-column>
                    <vxe-table-column :title="msg('仓库')" show-overflow field="stock.storeName" width="100">
                        <template v-slot="{ row }">
                            <div>{{row.stock?(row.stock.storeName?row.stock.storeName:"默认仓库"):""}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('仓库类型')" field="stock.storeType" width="70">
                        <template v-slot="{ row }">
                            <div>{{ getStoreType(row)}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('操作')" field="msg" width="70">
                        <template v-slot="{ row, rowIndex }">
                            <div>
                                <el-link v-if="hasPermission('stock:autoResendSearch')" :type="row.stock?'success':'danger'" :icon="row.stock?'el-icon-circle-check':'el-icon-circle-close'" style="font-size:20px;" :underline="false"></el-link>
                                <el-link v-if="hasPermission('stock:autoResendSearch')" type="primary" icon="el-icon-search" style="font-size:20px;padding-left:5px;" :underline="false" @click="searchStock(row, rowIndex, 1)"></el-link>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
                <vxe-table-colgroup :title="msg('其他')" field="group2" header-align="center">
                    <vxe-table-column field="resendId1" show-overflow :title="msg('货单备注')" width="150">
                        <template v-slot="{ row, rowIndex }">
                            <div style="text-align:center;">
                                 {{row.resendRemark}}
                                <el-link type="primary" icon="el-icon-edit"  @click="editRemark(row, rowIndex, 1)" style="font-size:18px;" :underline="false"></el-link>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
                <vxe-table-colgroup :title="msg('操作')" field="group3" header-align="center" fixed='right' >
                    <vxe-table-column field="resendId3"  min-width="70" :title="msg('操作')" >
                        <template v-slot="{ row, rowIndex }">
                            <div>
                                <el-link type="warning" icon="el-icon-refresh-right" style="font-size:20px;" :underline="false" @click="clearMatch(row, rowIndex)" ></el-link>
                                <el-link type="danger" icon="el-icon-delete" style="font-size:20px;padding-left:5px;" :underline="false" @click="removeRow(row, rowIndex, 1)"></el-link>
                            </div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
            </vxe-table>
        </div>

        <div v-if="errDatas.length>0" class="table-wrap" style="margin-top:15px;">
            <vxe-table border resizable 
                    size="mini" show-header-overflow
                    highlight-hover-row :max-height="height3"
                    :span-method="mergeRowMethod"
                    :scroll-y="{gt: -1}"
                    :data="errDatas">
                <vxe-table-colgroup class="gropu0" :title="msg('不能匹配的数据')" :fixed="isMinScreen?'':'left'" field="group0" header-align="center">
                    <vxe-table-column  field="resendId" :title="msg('订单编号')" width="120" sortable>
                        <template v-slot="{ row }">
                            {{row.resendCode}}
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('标题')" show-overflow  field="resendTitle" min-width="200">
                        <template v-slot="{ row }">
                            <div>{{row.resendTitle}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('规格')" show-overflow field="resendSpec" min-width="135">
                        <template v-slot="{ row }">
                            <div>{{row.resendSpec}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('货号')" show-overflow field="resendVariationSku" min-width="100">
                        <template v-slot="{ row }">
                            <div>{{row.resendVariationSku}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('SKU')" show-overflow field="resendSku" min-width="100">
                        <template v-slot="{ row }">
                            <div>{{row.resendSku}}</div>
                        </template>
                    </vxe-table-column>
                    <vxe-table-column :title="msg('件数')" field="resendQty" width="50"></vxe-table-column>
                    <vxe-table-column :title="msg('原因')" show-overflow field="msg" min-width="135">
                        <template v-slot="{ row }">
                            <div>{{row.msg}}</div>
                        </template>
                    </vxe-table-column>
                </vxe-table-colgroup>
            </vxe-table>
        </div>

        <div style="margin: 20px; text-align:center; ">
            <el-button type="primary" size="mini" icon="el-icon-check" @click="prepareResend">{{msg('确定转寄')}}</el-button>
        </div>

        <el-dialog :title="msg('查找库存')" :visible.sync="visible" :fullscreen="isMinScreen"  @opened="openedSearch"
            :close-on-click-modal="false" 
                width="1100px">
            <stock-search :stores="stores" :useds="useds" :resend="selectedResend" :type="selectType" :skuId="skuId"
                 ref="searchDg" v-on:close-dg="closeSearch"/>
        </el-dialog>
    </div>
</template>

<script>
import XEUtils from 'xe-utils';
import StockSearch from './StockMatchSearch.vue';
export default {
    components:{
        StockSearch: StockSearch,
    },
    mounted(){
        this.selectedResend = null;
        this.selectedIndex = null;
        this.selectType = null;
        this.datas = [];
        this.datas1 = [];
        this.errDatas = [];
        this.useds = [];
        this.storeIds = [];
        this.ids = this.$route.params.ids;
        this.getStores();
    },
    watch:{
      $route(){
        //跳转到该页面后需要进行的操作
        this.selectedResend = null;
        this.selectedIndex = null;
        this.selectType = null;
        this.datas = [];
        this.datas1 = [];
        this.errDatas = [];
        this.useds = [];
        this.storeIds = [];
        this.ids = this.$route.params.ids;
        this.getStores();
      }
    },
    data(){
        return{
            //当前选中的行
            selectedResend: null,
            //当前选中的行索引
            selectedIndex: null,
            selectType: null, //0-表示datas选择，1-datas1中选择, 2: 选中整条库存

            visible: false,
            
            ids: null, //待转寄的stock id列表
            storeIds: [],

            stores: [], //可选仓库列表
            useds: [], //正在被使用的库存
            //匹配成功
            datas: [],
            //需要手动匹配的数据
            datas1: [],
            errDatas: [],
            //非法的数据
            submitOk: false,
            height1: 600,
            height2: 600,
            height3: 600,
            skuId:null,
        }
    },
    computed: {
        docWidth: {  //是否小屏幕，浏览器
            get() {
                var width = this.$store.state.docWidth;
                return width;
            }
        },
        isMinScreen:{
            get() {
                var is = this.$store.state.isMinScreen;
                return is;
            }
        },
        routeTab: {
            get(){
                try{
                    return this.$store.state.activeMenus[this.$route.path]
                }catch{
                    return null;
                }
            }
        }
    },
    methods:{
        mergeRowMethod({ row, _rowIndex, column, visibleData }){
            const fields = ['resendCode','resendId','resendId1','resendId2','resendId3'];
            if(!column.property || !visibleData){
                return { rowspan: 0, colspan: 0 }
            }
            const cellValue = XEUtils.get(row, column.property)
            if (cellValue && fields.includes(column.property)) {
                const prevRow = visibleData[_rowIndex - 1]
                let nextRow = visibleData[_rowIndex + 1]
                if (prevRow && XEUtils.get(prevRow, column.property) === cellValue) {
                    return { rowspan: 0, colspan: 0 }
                } else {
                    let countRowspan = 1
                    while (nextRow && XEUtils.get(nextRow, column.property) === cellValue) {
                        nextRow = visibleData[++countRowspan + _rowIndex]
                    }
                    if (countRowspan > 1) {
                        return { rowspan: countRowspan, colspan: 1 }
                    }
                }
            }
        },
        getStoreType(row){
            if(!row.stock || !row.stock.storeType){
                return "";
            }

            let storeType = this.dicts.stockStoreType.find(e => e.value == row.stock.storeType);
            // console.log(storeType);
            if(storeType){
                return storeType.label;
            }
            return row.store.storeType;
        },
        getStores(){
            this.$axios.post(this.$kit.api.store.listUsed, {})
                .then( (result) => {
                    if(result.status){
                        let data = result.data;
                        data.splice(0, 0, {id: 0, name: '默认仓库'});
                        this.stores = data;
                    }
                });
        },
        //执行自动匹配，并得到自动匹配结果
        previewResend(){
            if(!this.ids || this.ids.length == 0){
                this.$message("没有要匹配的数据");
                return;
            }

            if(this.storeIds.length == 0){
                this.$message("请选择要匹配的仓库");
                return;
            }

            this.$axios.post(this.$kit.api.resend.preview, 
                    "ids="+this.ids.join(",")+"&storeIds="+(this.storeIds?this.storeIds.join(","):"0"), 
                    {headers: {'Content-Type' : 'application/x-www-form-urlencoded'}})
                .then( (result) => {
                    if(result.status){
                        let success = result.data.success;
                        let fails = result.data.fails;
                        let useds = result.data.useds;
                        let illegals = result.data.illegals;

                        success.forEach(s => {
                            s.resendId1 = s.resendId2 = s.resendId3 = s.resendId;

                            if(s.stock){
                                let used = useds.find(u => u.stockDetailId == s.stock.stockDetailId);
                                if(used){
                                    s.stock = used;
                                }else{
                                    useds.push(s.stock);
                                }
                            }
                        });

                        fails.forEach(s => {
                            s.resendId1 = s.resendId2 = s.resendId3 = s.resendId;
                            if(s.stock){
                                if(s.stock.stockDetailId){
                                    let used = useds.find(u => u.stockDetailId == s.stock.stockDetailId);
                                    if(used){
                                        s.stock = used;
                                    }else{
                                        useds.push(s.stock);
                                    }
                                }else{
                                    s.stock = null;
                                }
                               
                            }
                        });

                        if(success.length>0){
                            let height = (success.length * 40 + 80);
                            if(height>800){
                                height = 800;
                            }

                            if(height<450){
                                height =450;
                            }

                            this.height1 = height;
                        }

                        if(fails.length>0){
                            let height = (fails.length * 40 + 80);
                            if(height>800){
                                height = 800;
                            }

                            if(height<450){
                                height =450;
                            }

                            this.height2 = height;
                        } 

                        if(illegals.length>0){
                            let height = (illegals.length * 40 + 80);
                            if(height>800){
                                height = 800;
                            }

                            if(height<450){
                                height =450;
                            }

                            this.height3 = height;
                        }

                        this.datas = success;
                        this.datas1 = fails;
                        this.useds = useds;
                        this.errDatas = illegals;

                    }else{
                        this.$message(result.msg);  
                    }
                });
        },
        getExpData(){
            if(!this.ids || this.ids.length == 0){
                this.$message("没有要匹配的数据");
                return;
            }

            this.$axios.post(this.$kit.api.resend.findExps, 
                    "ids="+this.ids.join(","), 
                    {headers: {'Content-Type' : 'application/x-www-form-urlencoded'}})
                .then( (result) => {
                    if(result.status){
                        let fails = result.data.fails;
                        let illegals = result.data.illegals;

                        fails.forEach(s => {
                            s.resendId1 = s.resendId2 = s.resendId3 = s.resendId;
                        });

                        if(fails.length>0){
                            let height = (fails.length * 40 + 80);
                            if(height>800){
                                height = 800;
                            }

                            if(height<450){
                                height =450;
                            }

                            this.height2 = height;
                        } 

                        if(illegals.length>0){
                            let height = (illegals.length * 40 + 80);
                            if(height>800){
                                height = 800;
                            }

                            if(height<450){
                                height =450;
                            }

                            this.height3 = height;
                        }

                        this.datas1 = fails;
                        this.errDatas = illegals;

                    }else{
                        this.$message(result.msg);  
                    }
                });

        },

        //删除选中数据
        removeRow(row, rowIndex, type){
            this.confirmCtrl(this.msg('确定要取消转寄吗？'), ()=>{
                let ds = type === 0?this.datas : this.datas1;
                //ds.splice(rowIndex, 1);
                //先找到所有数据，然后还原数量
                let dels = ds.filter(d => d.resendId == row.resendId);
                dels.forEach(d => {

                    //还原数量，
                    if(d.stock){
                        d.stock.balanceQty += d.resendQty; 
                    }

                    //删除数据
                    let idx = ds.findIndex(d1 => d1.resendId == d.resendId && d1.resendDetailId == d.resendDetailId);
                    if(idx >- 1){
                        ds.splice(idx, 1);
                    }

                });
            });
        },
        tableRemove(){//批量移除
            var selectes = this.$refs.dataTable1.getCheckboxRecords();
            if(selectes.length==0){
                this.$message(this.msg('请选中数据进行操作'));
                return;
            }
            selectes.forEach(select => {
                let code = select.resendCode;

                this.datas1 = this.datas1.filter(d => {
                    
                    let result = d.resendCode != code;
                    if(!result){
                        if(d.stock){
                            d.stock.balanceQty += d.resendQty;
                        }
                    }

                    return  result;
                });
            })
        },
        
        //将已经匹配好的移动到为匹配好的列表中，同时移除库存
        moveToUnmatch(row, index){
            this.confirmCtrl(this.msg('确定手动匹配该数据吗？'), ()=>{
                //找出这条数据的所有数据
                let stocks = this.datas.filter(d => d.resendId == row.resendId);
                //找出所有主数据
                let mainStocks = stocks.filter(d => d.resendDetailId);
                
                //移除库存
                stocks.forEach(s => {
                    s.stock.balanceQty += s.resendQty;
                    s.stock = null;
                    s.resendMatched="";
                    s.resendStockCode = null;
                    s.resendStockId = null;
                    s.shelfCode = null;
                    s.storeId = null;
                    s.inStoreCode = null;
                    s.pkgType = null;

                    //删除数据
                    let idx = this.datas.findIndex(d1 => d1.resendId == s.resendId);
                    if(idx >- 1){
                        this.datas.splice(idx, 1);
                    }
                });

                //将子数据合并到主数据
                stocks.forEach(s => {
                    if(s.resendParentDetailId){
                        let mainStock = mainStocks.find(m => m.resendDetailId == s.resendParentDetailId);
                        if(mainStock){
                            mainStock.resendQty += s.resendQty;
                        }
                    }
                });

                //解决诡异的问题，单只有一条数据自动转寄成功时，并且移动到手动转寄时
                if(this.datas.length==0){
                    this.datas = [{}];
                    setTimeout(()=>{
                        this.datas = [];
                    }, 2000);
                }

                //移动到未匹配中
                mainStocks.forEach(m => {
                    ////解决诡异的问题，单只有一条数据自动转寄成功时，并且移动到手动转寄时
                    let m1 = {};
                    for(let key in m){
                        if(key != '_XID'){
                            m1[key]=m[key];
                        }
                    }
                    this.datas1.push(m1);
                });

            });

        },
        clearMatch(row, rowIndex){
            this.confirmCtrl(this.msg('确定要清空已匹配的数据吗？'), ()=>{
                //找出这条数据的所有数据
                let stocks = this.datas1.filter(d => d.resendId == row.resendId);
                //移除库存
                stocks.forEach(s => {

                    if(s.origin === 0){
                        if(s.stock){
                            s.stock.balanceQty += s.resendQty;
                        }
                    }else{
                        if(s.stock){
                            let usedIdx = this.useds.findIndex(u => u.stockId == s.stock.stockId);
                            if(usedIdx>-1){
                                this.useds.splice(usedIdx,1);
                            }
                        }
                    }

                    s.stock = null;
                    s.resendMatched="";
                    s.resendStockCode = null;
                    s.resendStockId = null;
                    s.shelfCode = null;
                    s.storeId = null;
                    s.inStoreCode = null;
                    s.pkgType = null;
                });

                //找出所有主数据
                let mainStocks = stocks.filter(d => d.resendDetailId);

                //将子数据合并到主数据
                stocks.forEach(s => {
                    if(s.resendParentDetailId){
                        let mainStock = mainStocks.find(m => m.resendDetailId == s.resendParentDetailId);
                        if(mainStock){
                            mainStock.resendQty += s.resendQty;
                        }
                    }
                });


                //找出子数据，并删除
                let removes = stocks.filter(d => d.resendParentDetailId);
                removes.forEach(s => {
                    //删除数据
                    let idx = this.datas1.findIndex(d1 => d1.resendId == s.resendId && d1.resendParentDetailId == s.resendParentDetailId);
                    if(idx >- 1){
                        this.datas1.splice(idx, 1);
                    }

                });
            });
        },

        confirmCtrl(text, cb){
            this.$confirm(this.msg(text), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
                type: 'warning'
            }).then(() => {
               cb();
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消操作'
                });          
            });
        },

        //搜索库存
        searchStock(row, rowIndex, type){
            this.skuId = row.resendDetailId;
            this.selectedResend = row;
            this.selectedIndex = rowIndex;
            this.selectType = type;
            this.visible = true;
        },

        //打开搜索弹窗之后
        openedSearch(){
            this.$nextTick(()=>{
                this.$refs.searchDg.init();
            });
        },
        //按条搜索库存
        searchMainStock(){
            var selectes = this.$refs.dataTable1.getCheckboxRecords();
            if(selectes.length==0 || selectes.length>1){
                this.$message(this.msg('请选中一条数据进行操作'));
                return;
            }

            //判断选中的数据是不是存在已经匹配的
            let select = selectes[0];
            let resends = this.datas1.filter(s => s.resendId == select.resendId);
            for(let i = 0; i<resends.length; i++){
                let resend = resends[i];
                if(resend.stock){
                    this.$message(this.msg('请先清空转寄，在执行该操作'));
                    return;
                }
            }

            this.selectedResend = selectes[0];
            this.selectType = 2;
            this.visible = true;
        },
        //关闭弹窗选择数据
        closeSearch(selectes,type){
            // console.log("type:",type);
            //如果选中的不为空，说明选了库存，将待匹配的数据和库存关联起来
            if(selectes){
                if(this.selectType == 2){
                    //这里是整包转寄
                    
                    //查到所有待转寄数据
                    let exps = this.datas1.filter(s => s.resendId == this.selectedResend.resendId);
                    if(exps.length>0){
                        
                        let stock = selectes[0];

                        let newused1 = {
                            balanceQty: 0,
                            stockCode: stock.code,
                            stockDetailId:stock.detailId,
                            stockId: stock.id,
                            stockSpec: null,
                            stockTitle: null,
                            storeId: stock.storeId,
                            storeName: stock.storeName, 
                            storeType: stock.storeType,
                            totalQty: stock.productQty,
                            shelfCode: stock.shelfCode,
                            inStoreCode: stock.inStoreCode,
                            inStoreDate: stock.inStoreTime,
                            status: stock.status,
                            searchType: type
                        };

                        exps.forEach(e => {
                            e.resendMatched="成功";
                            e.resendStockCode = stock.code;
                            e.resendStockId = stock.id;
                            e.shelfCode = stock.shelfCode;
                            e.storeId = stock.storeId;
                            e.inStoreCode = stock.inStoreCode || stock.code;
                            e.stock = newused1,
                            e.origin = 1
                        });

                        this.useds.push(newused1);

                        for(let i=1; i<selectes.length; i++){
                            
                            let select = selectes[i];
                            if(select){
                                let newused = {
                                    balanceQty: 0,
                                    stockCode: select.code,
                                    stockDetailId:select.detailId,
                                    stockId: select.id,
                                    stockSpec: select.productSpec,
                                    stockTitle: select.productTitle,
                                    storeId: select.storeId,
                                    storeName: select.storeName, 
                                    storeType: select.storeType,
                                    totalQty: select.productQty,
                                    shelfCode: select.shelfCode,
                                    inStoreCode: select.inStoreCode,
                                    inStoreDate: select.inStoreTime,
                                    status: select.status,
                                    searchType: type
                                };
                                this.useds.push(newused);
                            }

                        }
                       
                        this.visible = false;
                    }
                }else{
                    // 待匹配数量
                    let qty = this.selectedResend.resendQty;
                    //如果之前匹配过，则恢复数量
                    let preused = this.selectedResend.stock;
                    if(preused){
                        preused.balanceQty += qty;
                    }

                    //开始匹配
                    let index = 0;
                    for(let i=0; i<selectes.length; i++){

                        if(qty<=0){
                            break;
                        }

                        let stock = selectes[i];

                        //let used = this.useds.find(u => u.stockDetailId == stock.detailId);
                        
                        //库存数量
                        let stockQty = stock.balanceQty;
                        //需要的数量
                        let needQty = 0;
                        //库存剩余的数量
                        let balanceQty = 0;

                        //sotck数据返回的时候，已经将剩余的关联起来了
                        if(stock.used){
                            stockQty = stock.used.balanceQty;
                        }
                        if(stockQty<1){
                            continue;
                        }

                        if(stockQty >= qty) {
                            needQty = qty;
                            balanceQty = stockQty - qty;
                            qty = 0;
                        }else {
                            needQty = stockQty;
                            qty = qty - stockQty;
                            balanceQty = 0;
                        }

                        //修改原数据
                        if(index == 0){
                            this.selectedResend.resendMatched="成功";
                            this.selectedResend.resendQty = needQty;
                            this.selectedResend.resendStockCode = stock.code;
                            this.selectedResend.resendStockId = stock.id;
                            this.selectedResend.shelfCode = stock.shelfCode;
                            this.selectedResend.storeId = stock.storeId;
                            this.selectedResend.origin = 0;
                            this.selectedResend.inStoreCode = stock.inStoreCode || stock.code;

                            if(stock.used){
                                stock.used.balanceQty = balanceQty;
                                this.selectedResend.stock = stock.used;
                            }else{
                                let newused = {
                                    balanceQty: balanceQty,
                                    stockCode: stock.code,
                                    stockDetailId:stock.detailId,
                                    stockId: stock.id,
                                    stockSpec: stock.productSpec,
                                    stockTitle: stock.productTitle,
                                    storeId: stock.storeId,
                                    storeName: stock.storeName, 
                                    storeType: stock.storeType,
                                    totalQty: stock.productQty,
                                    shelfCode: stock.shelfCode,
                                    inStoreCode: stock.inStoreCode,
                                    inStoreDate: stock.inStoreTime,
                                    status: stock.status,
                                    searchType: type
                                };
                                this.selectedResend.stock = newused;
                                this.useds.push(newused);
                            }

                        }else{
                            let newresend = {
                                resendCode:this.selectedResend.resendCode,
                                resendId: this.selectedResend.resendId,
                                resendMatched:"成功",
                                resendParentDetailId:this.selectedResend.resendDetailId || this.selectedResend.resendParentDetailId,
                                resendQty:needQty,
                                resendRemark:this.selectedResend.remark2,
                                resendSpec:this.selectedResend.resendSpec,
                                resendVariationSku:this.selectedResend.resendVariationSku,
                                resendSku:this.selectedResend.resendSku,
                                resendStockCode:stock.code,
                                resendStockId:stock.id,
                                owner:this.selectedResend.owner,
                                productId:this.selectedResend.productId,
                                productName:this.selectedResend.productName,
                                productPrice:this.selectedResend.productPrice,
                                resendTitle:this.selectedResend.resendTitle,
                                shelfCode: stock.shelfCode,
                                inStoreCode: stock.inStoreCode || stock.code,
                                storeId: stock.storeId,
                                stock: null,
                                origin: 0,
                            }

                            newresend.resendId1 = newresend.resendId2 = newresend.resendId3 = newresend.resendId; 

                            if(stock.used){
                                stock.used.balanceQty = balanceQty;
                                newresend.stock = stock.used;
                            }else{
                                let newused = {
                                    balanceQty: balanceQty,
                                    stockCode: stock.code,
                                    stockDetailId:stock.detailId,
                                    stockId: stock.id,
                                    stockSpec: stock.productSpec,
                                    stockTitle: stock.productTitle,
                                    storeId: stock.storeId,
                                    storeName: stock.storeName, 
                                    storeType: stock.storeType,
                                    totalQty: stock.productQty,
                                    shelfCode: stock.shelfCode,
                                    inStoreCode: stock.inStoreCode,
                                    inStoreDate: stock.inStoreTime,
                                    status: stock.status,
                                    searchType: type
                                };
                                newresend.stock = newused;
                                this.useds.push(newused);
                            }

                            if(this.selectType === 0){
                                this.datas.splice(this.selectedIndex+index+1,0,newresend);
                            }else if(this.selectType == 1){
                                this.datas1.splice(this.selectedIndex+index+1,0,newresend);
                            }

                        }

                        index ++;
                    }
                    
                    //如果匹配选择的数据匹配完了，qty还有，则生成一条新的
                    if(qty>0 && index>0){
                        let newresend = JSON.parse(JSON.stringify(this.selectedResend));
                        if(!newresend.resendParentDetailId){
                            newresend.resendParentDetailId = newresend.resendDetailId;
                        }

                        newresend.resendDetailId =null;
                        newresend.resendStockId = null;
                        newresend.resendStockCode = null;
                        newresend.resendQty = qty;
                        newresend.stock = null;

                        if(this.selectType === 0){
                            this.datas.splice(this.selectedIndex+1,0,newresend);
                        }else if(this.selectType == 1){
                            this.datas1.splice(this.selectedIndex+1,0,newresend);
                        }
                    }
                    // console.log(this.datas1);
                    // console.log(this.useds);
                    // console.log(this);
                    // this.$refs.dataTable.reloadData(this.datas);
                    // this.$refs.dataTable1.reloadData(this.datas1);
                    this.visible = false;
                }
            }else{
                this.$message(this.msg("请选择库存"));
            }
        },
        //准备转寄
        prepareResend(){
            
            let _this = this;

            if(this.submitOk){
                this.$message({
                    type: 'danger',
                    message: this.msg('已经成功匹配，不能再次提交')
                });
                return;
            }

            if(this.datas1 && this.datas1.length > 0){
                let msg;
                let isOk = true;
                this.datas1.forEach(e => {
                    this.datas1.forEach(f => {
                        if(e.resendCode == f.resendCode){
                            if(e.storeId != f.storeId){
                                msg = '订单：'+e.resendCode+'，转寄了不同仓库的货物，同一订单不能转寄不同仓库货物；请更正！';   
                                isOk = false;                         }
                        }
                    })
                })
                if(!isOk){
                    this.$message(msg);
                    return;
                }
            }

            this.$confirm(this.msg('确定要保存转寄结果吗？'), this.msg('提示'), {
                    confirmButtonText: this.msg('确定'),
                    cancelButtonText: this.msg('取消'),
                    type: 'warning'
                }).then(() => {
                    _this.doResend();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: this.msg('取消')
                    });          
            });

        },
        //保存转寄数据
        doResend(){
            let resends = [];
        
            //判断是不是存在，还没关联好的数据如果存在则不提交
            for(let i=0;i<this.datas.length; i++){
                let d = this.datas[i];
                if(d.stock || d.stock.stockDetailId){
                    resends.push(d);
                }else{
                    this.$message({
                        type: 'warning',
                        message: this.msg('自动匹配成功的订单列表中，还存在没有转寄的数据')
                    });  
                    return;
                }
            }

            for(let i=0;i<this.datas1.length; i++){
                let d = this.datas1[i];
                if(d.stock){
                    resends.push(d);
                }else{
                    this.$message({
                        type: 'warning',
                        message: this.msg('手动匹配成功的订单列表中，还存在没有转寄的数据')
                    });  
                    return;
                }
            }

            if(resends.length == 0){
                this.$message({
                        type: 'warning',
                        message: this.msg('没有要转寄的数据')
                    });  
                return ;
            }

            this.$axios.post(this.$kit.api.resend.save+'?selectType='+this.selectType, resends)
                .then( (result) => {
                    this.$message(result.msg);
                    if(result.status){
                        this.submitOk = true;
                    }else{

                        if(result.data){
                            //表示库存不足，
                            let temps = result.data;
                            let dbStock = [];
                            // {id: qty} =>{id: "", qty:""}
                            for(let key in temps){
                                dbStock.push({
                                    id: key,
                                    qty: temps[key]
                                });
                            }
                            //移除关联，回复库存数量
                            dbStock.forEach(bs => {
                                this.datas.forEach(d => {
                                    if(d.stock && d.stock.stockDetailId ==  bs.id){
                                        d.stock = null;
                                        d.resendStockId = null;
                                        d.resendStockCode = null;
                                    }
                                });
                                this.datas1.forEach(d => {
                                    if(d.stock && d.stock.stockDetailId ==  bs.id){
                                        d.stock = null;
                                        d.resendStockId = null;
                                        d.resendStockCode = null;
                                    }
                                });
                                
                                let idx = this.useds.findIndex(u => u.stockDetailId == bs.id);
                                if(idx > -1){
                                    this.useds.splice(idx, 1);
                                }
                            });
                        }
                    }
                });
        },
        //编辑货单备注
        editRemark(row, rowIndex, type){
            this.$prompt(this.fmt('编辑%s的货单备注', [row.resendCode]), this.msg('提示'), {
                confirmButtonText: this.msg('确定'),
                cancelButtonText: this.msg('取消'),
            }).then(({ value }) => {

                let ds = type == 1?this.datas1 : this.datas;
                let selecteds = ds.filter(d => d.resendId == row.resendId);
                selecteds.forEach(d => {
                    d.resendRemark = value;
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });  
            });
        },
        //点击checkbox
        changeData1Select({row, checked}){
            // console.log(row, checked);

            //将合并后的其他行也设置为不选中
            if(!checked){
                let {tableData} = this.$refs.dataTable1.getTableData();
                
                let rows = [];
                tableData.forEach(e => {
                    if(e.resendId == row.resendId){
                        rows.push(e);
                    }
                });

                this.$refs.dataTable1.setCheckboxRow(rows, false);
            }
        },

        //设置表格样式
        mytd({ row, rowIndex, column, columnIndex }){
            if(['resendId','resendTitle','resendSpec','resendSku','resendVariationSku', 'resendQty', 'resendStockCode'].indexOf(column.property)>-1){
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    backgroundColor:"rgba(120,188,248,0.1)"
                }
            }else if(['stockQty','stockCode', 'stockSpec', 'stockTitle', 'storeName','storeType', 'msg'].indexOf(column.property)>-1){
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    backgroundColor:"rgba(239,166,127,0.1)"
                }
            }else{
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    backgroundColor:"rgba(174,255,139,0.1)"
                }
            }
        },
        myth({ column, columnIndex }){
            if(["group0",'group1','group2', 'group3'].indexOf(column.property)>-1){
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    borderRight: column.property=='group2'?"1px solid rgba(0,0,0,0.7)":'',
                    backgroundColor:"rgba(217,217,217,1)"
                }
            }else if(['resendId','resendTitle','resendSpec','resendSku','resendVariationSku', 'resendQty', 'resendStockCode'].indexOf(column.property)>-1){
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    backgroundColor:"rgba(120,188,248,0.1)"
                }
            }else if(['stockQty','stockCode', 'stockSpec', 'stockTitle', 'storeName','storeType', 'msg'].indexOf(column.property)>-1){
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    backgroundColor:"rgba(239,166,127,0.1)"
                }
            }else{
                return {
                    borderTop: "1px solid rgba(0,0,0,0.7)",
                    borderLeft: "1px solid rgba(0,0,0,0.7)",
                    backgroundColor:"rgba(174,255,139,0.1)"
                }
            }
           
        }

        //end 设置表格样式

    }

}
</script>

<style lang="scss" scoped>
    .resend-box h2{padding-left: 15px; font-size:20px; color: rgba(100,100,100,0.9)}
    .resend-box h3{padding-left: 15px; font-size:16px; color: rgba(100,100,100,0.9)}
    .mytable-style{
        border: 1px solid rgba(0,0,0,0.7) !important;
    }
</style>